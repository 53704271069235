import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';
import App from './App';
import axios from 'axios';

// Init VK Mini App bridge
bridge.send('VKWebAppInit');

// Init axios
axios.defaults.baseURL = 'https://kfsamara.ru/api';

ReactDOM.render(<App />, document.getElementById('root'));