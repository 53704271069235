import { useContext, useEffect, useState } from 'react';
import Cart from '../../components/main/Cart';
import Products from '../../components/main/Products';
import './styles.css';
import { CartStateContext, OrderStateContext } from '../../utils/contexts';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { cartActive, setCartActive } = useContext(CartStateContext)!;
  const { order } = useContext(OrderStateContext)!;
  const navigate = useNavigate();

  let cartSum = 0;

  for (const position of order.items) {
    cartSum += position.quantity! * position.price;
  }

  return (
    <div>
      <div className="container">
        <div className="catalog">
          <Products />
          <Cart />
        </div>
      </div>
      
      { !!order.items.length && <div className="cart-block" onClick={() => setCartActive(true)}>
        { cartActive ?
        <div className="cart-button">
          <button className="btn btn-placeholder" onClick={() => {
            navigate('/order');
            document.body.style.overflow = 'scroll';
          }}>Оформить заказ</button>
        </div> : 
        <div className="cart-block-items">
          <span>Корзина</span>
          <span className="total">{ cartSum } ₽</span>
        </div> }
      </div> }

    </div>
  )
}

export default Home;