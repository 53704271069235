import './styles.css';
import { useContext } from 'react';
import { CartStateContext, UserContext } from '../../../utils/contexts';
import { useNavigate } from 'react-router-dom';
import bridge from '@vkontakte/vk-bridge';

const Header = () => {
  const user = useContext(UserContext);
  const { setCartActive } = useContext(CartStateContext)!;
  const navigate = useNavigate();

  const navigateHome = () => {
    setCartActive(false);
    navigate('/');
  }

  return (
    <header>
      <div className="container">
        <div className="inner">
          <img src="/kf.logo.svg" width={200} onClick={navigateHome} />
        </div>
      </div>
    </header>
  )
}

export default Header;