import './styles.css';
import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Category from '../Category';
import ProductCard from '../ProductCard';
import Modal from '../Modal';
import IModal from '../../../interfaces/Modal';
import { OrderStateContext } from '../../../utils/contexts';

const Products = () => {
  const [category, setCategory] = useState<string>('Все');
  const [items, setItems] = useState<any[]>([]);
  const [modal, setModal] = useState<IModal>();
  const { order, setOrder } = useContext(OrderStateContext)!;

  const selectCategory = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (event.currentTarget.id == 'Все') {
      document.documentElement.scrollTop = 0;      
    } else {
      const element = document.getElementById(`products-${event.currentTarget.id}`)!;

      const y = element.getBoundingClientRect().top + window.pageYOffset - 175;
      window.scrollTo({top: y, behavior: 'smooth'});

    }

    setCategory(event.currentTarget.id);
  }

  // onMount
  useEffect(() => {
    async function fetchProducts() {  
      const respProducts = await axios.post('/products/get',
        {
          conditions: [
            {
              k: 'status',
              v: 1
            },
            {
              k: 'siteHide',
              v: 0
            }
          ],
          orders: [
            {
              k: 'sortOrder',
              isd: 1
            }
          ]
        }, 
        {
          headers: {
            Authentication: process.env.REACT_APP_KF_AUTH_KEY
          }
        }
      );

      const respSections = await axios.post('/sections/get', {}, {
        headers: {
          Authentication: process.env.REACT_APP_KF_AUTH_KEY
        }
      });

      const products = [];
      const sections = respSections.data;
      const productsAll = respProducts.data;

      const ps = productsAll.data.filter((f: any) => sections.data.find((s: any) => s.id === f.sectionId) !== undefined).reduce((r: any, a: any) => {
        const id = a.sectionId;
        r[id] = [...r[id] || [], a];
        return r;
      }, {});

      for (const v of Object.values(ps) as any) {
        const cats = v.reduce((r: any, a: any) => {
          const id = a.categoryId;
          r[id] = [...r[id] || [], a]
          return r
        }, {})

        products[v[0].sectionId] = cats;
      }

      setItems(products);
    }

    fetchProducts();
  }, []);

  useEffect(() => {
    if (!modal?.item.quantity) return;
    
    let updatedOrder = [];
    let isUpdated = false;

    for (const position of order.items) {
      if (position.name == modal.item.name) {
        updatedOrder.push({
          ...position,
          quantity: position.quantity! += modal.item.quantity
        });
        isUpdated = true;
      } else {
        updatedOrder.push(position);
      }
    }

    if (!isUpdated) updatedOrder.push(modal.item);
     
    setOrder({ ...order, items: updatedOrder });

    setModal(undefined);
  }, [modal]);

  return (
    <div className="products">
      { modal?.active && 
      <>
        <Modal
          modal={modal}
          setModal={setModal}
        /> 
        <div className="modal-overlay" style={{backgroundColor: 'rgb(0, 0, 0)', opacity: 0.6}}></div>
      </>}
      <div id="scroll-top"></div>
      <nav id="categories">
        <ul>
          <Category title="Все" icon="all" onClick={selectCategory} selectedCategory={category} />
          <Category title="Роллы" icon="rolls" onClick={selectCategory} selectedCategory={category} />
          <Category title="Бургеры" icon="burgers" onClick={selectCategory} selectedCategory={category} />
          <Category title="Шашлык" icon="shashlik" onClick={selectCategory} selectedCategory={category} />
          <Category title="Напитки" icon="napitki" onClick={selectCategory} selectedCategory={category} />
        </ul>
      </nav>
      {items.length ? items.map(sections => (
        <div
          key={(Object.values(sections)[0] as Array<any>)[0].sectionName}
          id={`products-${(Object.values(sections)[0] as Array<any>)[0].sectionName}`
        }>
          {Object.keys(sections).map((sectionId: any) => (
            <div key={sectionId}>
              <h3>{ sections[sectionId][0].categoryName || sections[sectionId][0].sectionName  }</h3>
              <div className="products-content">
                {sections[sectionId].map((item: any) => (
                  <ProductCard
                    key={item.id} 
                    item={item}
                    setModal={setModal}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
    )) : <div className="loader" />}
    </div>
  )
}

export default Products;