import IItem from '../../../interfaces/Item';
import './styles.css';

const ProductCard = ({ item, setModal }: {
  item: IItem
  setModal: React.Dispatch<React.SetStateAction<any>>,
}) => {
  const openModal = () => {
    setModal({
      active: true,
      item: item
    });
  }

  return (
    <div className="card" onClick={openModal}>
      <div className="item">
        <div className="info">
          <div className="title">
            { item.name } 
            { item.measure && <span>{ item.measure } г</span> }
          </div>
          <div className="price">
            { item.price }₽
          </div>
        </div>
        <div className="thumb">
          <img src={`https://kfsamara.ru/assets/products/${item.image}`} />
        </div>
      </div>
    </div>
  )
}

export default ProductCard;