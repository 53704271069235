import axios from 'axios';
import './styles.css';
import InputMask from 'react-input-mask';
import { useContext, useEffect, useRef, useState } from 'react';
import { CartStateContext, OrderStateContext } from '../../utils/contexts';
import bridge, { UserInfo } from '@vkontakte/vk-bridge';
import { useNavigate } from 'react-router-dom';

const Order = () => {   
  const navigate = useNavigate();
  const { order, setOrder } = useContext(OrderStateContext)!;

  if (order.items.length == 0) {
    navigate('/');
  };

  const [ form, setForm ] = useState({
    phone: '',
    address: {
      latitude: '',
      longitude: '',
      title: ''
    },
    apartment: '',
    entrance: '',
    floor: '',
    intercom: '',
    comment: '',
    promocode: '',
    isCardPayment: true,
    isDelivery: true,
    areas: []
  });
  const addressRef = useRef('');
  const isDeliveryRef = useRef(true);

  const [ errors, setErrors ] = useState({
    phone: '',
    address: '',
    apartment: '',
    entrance: '',
    floor: '',
    intercom: '',
    comment: '',
    promocode: ''
  });

  const [ yandexAddresses, setYandexAddresses ] = useState<Array<any>>([]);
  
  const [ deliveryCost, setDeliveryCost ] = useState(0);
  const [ priceFree, setPriceFree ] = useState(0);

  const [ storageLoaded, setStorageLoaded ] = useState(false);

  let cartSum = 0;
  
  for (const position of order.items) {
    cartSum += position.quantity! * position.price;
  }  

  useEffect(() => {
    setForm(JSON.parse(localStorage.getItem('orderForm')!));
    setStorageLoaded(true);
  }, []);

  useEffect(() => {
    async function getCartSum() {
      if (!form.isDelivery) {
        setDeliveryCost(0);
      } else {  
        const deliveryData = (await axios.post('/deliveryareas/byAreaDefaultGet', {
          latitude: form.address.latitude,
          longitude: form.address.longitude
        }, {
          headers: {
            Authentication: process.env.REACT_APP_KF_AUTH_KEY
          }
        })).data;
        
        setPriceFree(deliveryData.data.priceFree);

        setDeliveryCost(cartSum < deliveryData.data.priceFree ? deliveryData.data.price : 0);
      }
    }

    if (storageLoaded) getCartSum();
  }, [isDeliveryRef.current, addressRef.current])

  useEffect(() => {
    let nowErrors: any = {}; 

    // address
    if (form.address) nowErrors.address = '';

    // phone
    if (form.phone) {
      let phoneDigit = '';

      for (const element of form.phone) {
        if (/^\d+$/.test(element)) phoneDigit += element;
      }
  
      if (phoneDigit.slice(0, 11).length == 11) {
        nowErrors.phone = '';
      }  
    }

    // promocode
    if (!form.promocode) nowErrors.promocode = '';
    
    // state update
    setErrors({
      ...errors,
      ...nowErrors
    });   

    // localstorage loading
    localStorage.setItem('orderForm', JSON.stringify(form));

    // refs update
    addressRef.current = form.address.title;
    isDeliveryRef.current = form.isDelivery;
  }, [form])

  useEffect(() => {
    if (!addressRef.current) return;

    const text = `Самара, ${addressRef.current}`;
    
    async function getYandexAddresses() {
      const resp = await axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=${process.env.REACT_APP_YANDEX_MAPS_KEY}`
        +`&format=json&lang=ru_RU&geocode=${encodeURIComponent(text)}`);

      setYandexAddresses(resp.data.response.GeoObjectCollection.featureMember);
    }

    getYandexAddresses();
  }, [addressRef.current]);

  useEffect(() => {
    if (isDeliveryRef.current) return;

    async function getAreas() {
      const resp = await axios.post('/areas/get', {
        conditions: [
          {
            k: 'status',
            v: 1
          }
        ]
      }, {
        headers: {
          Authentication: process.env.REACT_APP_KF_AUTH_KEY
        }
      });

      setForm({ ...form, areas: resp.data.data.map((area: any) => ({ ...area, selected: area.id == 1}))});
    }

    getAreas();
  }, [isDeliveryRef.current]);

  const setFormValue = (e: React.FormEvent<HTMLInputElement>) => 
    setForm({ ...form, [e.currentTarget.id]: e.currentTarget.value });

  const getPhone = () => {
    async function getPhoneNumber() {
      const data = await bridge.send('VKWebAppGetPhoneNumber');
      setForm({ ...JSON.parse(localStorage.getItem('orderForm')!), phone: data.phone_number });
    }
    
    getPhoneNumber();
  }

  const validate = async () => {
    let nowErrors: any = {}

    // address
    if (!form.address.latitude || !form.address.longitude || !form.address.title) {
      nowErrors.address = 'Вы должны указать адрес из списка';
    }

    // phone
    let phoneDigit = '';

    for (const element of form.phone) {
      if (/^\d+$/.test(element)) phoneDigit += element;
    }

    if (phoneDigit.slice(0, 11).length < 11) {
      nowErrors.phone = 'Введите телефон для связи';
    }

    // promocode
    await axios.post('/promocodes/check', form.promocode, {
      headers: {
        Authentication: process.env.REACT_APP_KF_AUTH_KEY
      }
    }).catch(() => {
      if (form.promocode) {
        nowErrors.promocode = 'Неправильный промокод';
      }  
    })

    return nowErrors;
  }

  const submit = async () => {
    const nowErrors = await validate();

    if (!!Object.keys(nowErrors).length) {
      document.documentElement.scrollTop = 0;      
      setErrors({
        ...errors,
        ...nowErrors,
      });
      return;
    }

    let products = '';
    let counter = 0;

    for (const product of order.items) {
      counter += 1;
      products += `${counter} ${product.name}: ${product.price} ₽ (${product.quantity} шт.)\n`
    }

    let areaId;
    if (!form.isDelivery) {
      areaId = (form.areas.filter((v: any) => v.selected)[0] as any).id;
    }

    const createData = {
      cutlery: order.cutlery,
      price: cartSum,
      products,
      deliveryIsFree: Number(cartSum >= priceFree),
      deliveryCost: deliveryCost,  
      priceFull: deliveryCost + cartSum,
      phone: form.phone.replace(/\D/g, '').slice(0, 11),
      address: form.address.title,
      latitude: parseFloat(form.address.latitude),
      longitude: parseFloat(form.address.longitude),
      paymentType: !Number(form.isCardPayment),
      isPickup: !Number(form.isDelivery),
      comment: form.comment,
      type: 0,
      target: 5,
      areaId,
    };

    const res = await axios.post('/orders/create', {
      data: createData,
      cart: order.items
    }, {
      headers: {
        Authentication: process.env.REACT_APP_KF_AUTH_KEY
      }
    });

    if (!form.isCardPayment) {
      navigate('/payment/success');
      setOrder({
        items: [],
        cutlery: 1
      });
      localStorage.setItem('order', JSON.stringify({
        items: [],
        cutlery: 1
      }));
      return;
    }
  
    const systemPaymentType = Number((await axios.post('/settings/byCodeGet', { code: 'system-payment-type'}, { 
      headers: {
        Authentication: process.env.REACT_APP_KF_AUTH_KEY
      }
    })).data.data.value);

    if (systemPaymentType  == 0) {
      navigate(`http://kfsamara.ru/payment/?id=${res.data.data.id}`);
    } else {
      // @ts-ignore
      pay(res.data.data.id, createData, { phone: form.phone })  
        .then((isPayed: boolean) => {
          console.log(isPayed);
          if (isPayed) {
            setOrder({
              items: [],
              cutlery: 1
            });
            localStorage.setItem('order', JSON.stringify({
              items: [],
              cutlery: 1
            }));
            navigate('/payment/success');
          } else {
            navigate('/payment/fail');
          }
        })
    }
  }

  return (
    <div>
      <div className="container">
        <div className="products-order-wrap">
          <div className="products-order">
            <div className="column">
              <h3>Телефон</h3>
              <div className="row row-wide row-address">
                <label>
                  <span className="row-title-empty"></span>
                  <InputMask
                    id="phone"
                    className={errors.phone && 'is-error'}
                    type="tel"
                    autoComplete="off"
                    mask="+7 (999) 999-99-99" 
                    onInput={setFormValue}
                    value={form ? form.phone : '+7'}
                  />
                  <button onClick={getPhone}>Получить телефон</button>
                  <div className="error-hint">{ errors.phone }</div>
                </label>
              </div>
              <h3>Доставка</h3>
              <div className="row row-wide row-address">
                <label>
                  <span>Адрес доставки</span>
                  <input
                    id="address"
                    className={errors.address && 'is-error'}
                    list="address"
                    type="text"
                    autoComplete="off"
                    onInput={(e) => setForm({ ...form, address: { ...form.address, title: e.currentTarget.value } })}
                    value={form.address.title}
                  />
                  <div className="error-hint">{ errors.address }</div>
                </label>
                { yandexAddresses.length != 0 && document.activeElement?.id == 'address' && <div className="addresses-list-inner">
                  <ul>
                    { yandexAddresses.map(yandexAddress => (
                      <li
                        onClick={async () => {
                          const [longitude, latitude] = yandexAddress.GeoObject.Point.pos.split(' ');

                          if (yandexAddress.GeoObject.metaDataProperty.GeocoderMetaData.kind != 'house') {
                            alert('В адресе должен быть указан дом');
                            setForm({ ...form, address: {
                              latitude: '',
                              longitude: '',
                              title: ''
                            }});
                            return;
                          }

                          setForm({ ...form, address: {
                            ...form.address,
                            latitude,
                            longitude,
                            title: yandexAddress.GeoObject.name
                          }})
                        }}
                      >
                        { yandexAddress.GeoObject.name }
                        <span>{ yandexAddress.GeoObject.description }</span>
                      </li>
                    ))}
                  </ul>
                </div>}
              </div>  
              <div className="row row-wide row-address">
                <label>
                  <span>Квартира/Офис</span>
                  <input
                    id="apartment"
                    type="text"
                    maxLength={4}
                    autoComplete="off"
                    onInput={setFormValue}
                    value={form.apartment}
                  />
                </label>
              </div>
              <div className="row row-wide row-address">
                <label>
                  <span>Подъезд</span>
                  <input
                    id="entrance"
                    type="text"
                    maxLength={2}
                    autoComplete="off"
                    onInput={setFormValue}
                    value={form.entrance}
                  />
                </label>
              </div>
              <div className="row row-wide row-address">
                <label>
                  <span>Этаж</span>
                  <input
                    id="floor"
                    type="text"
                    maxLength={2}
                    autoComplete="off"
                    onInput={setFormValue}
                    value={form.floor}
                  />
                </label>
              </div>
              <div className="row row-wide row-address">
                <label>
                  <span>Домофон</span>
                  <input
                    id="intercom"
                    className={errors.intercom && 'is-error'}
                    type="text"
                    maxLength={4}
                    autoComplete="off"
                    onInput={setFormValue}
                    value={form.intercom}
                  />
                  <div className="error-hint">{ errors.intercom }</div>
                </label>
              </div>
              <div className="row row-wide">
                <label>
                  <span>Комментарий к заказу</span>
                  <input
                    id="comment"
                    type="text"
                    autoComplete="off"
                    onInput={setFormValue}
                    value={form.comment}
                  />
                </label>
              </div>
              <h3>Промокод</h3>
              <div className="row row-wide row-promocode">
                <label>
                  <span className="row-title-empty"></span>
                  <input
                    id="promocode"
                    className={errors.promocode && 'is-error'}
                    type="text"
                    autoComplete="off"
                    maxLength={50}
                    onInput={setFormValue}
                    value={form.promocode}
                  />
                  <div className="error-hint">{ errors.promocode }</div>
                </label>
              </div>
              <h3>Способы оплаты</h3>
              <div className="row-payment row-payment-card">
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked={form.isCardPayment}
                    onChange={() => setForm({ ...form, isCardPayment: true })}
                  />
                  <span>Картой онлайн</span>
                </label>
                { form.isCardPayment && <div className="row-content">
                  <p>Оплата происходит через сервис CloudPayments с использованием банковских карт платёжных систем</p>
                  <img src="/img/cards2.svg" alt="" width="300" />
                </div> }
              </div>
              <div className="row-payment row-payment-cash">
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={!form.isCardPayment}
                    onChange={() => setForm({ ...form, isCardPayment: false })}
                  />
                  <span>Наличными курьеру</span>
                </label>
                { !form.isCardPayment && <div className="row-content">
                  <p>Вы можете оплатить заказ при получении наличными</p>
                  <label className="surrender">
                    <span>Нужна сдача с суммы</span>
                    <input type="number" name="surrender" min="0" />
                  </label>
                </div>}
              </div>
              <h3>Получение</h3>
              <div className="row-payment row-pickup-delivery">
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked={form.isDelivery}
                    onChange={() => setForm({ ...form, isDelivery: true })}
                  />
                  <span>Доставка</span>
                </label>
                {form.isDelivery && <div className="row-content">
                  <p>Заказ доставит наш курьер</p>
                </div>}
              </div>  
              <div className="row-payment row-pickup-pickup">
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={!form.isDelivery}
                    onChange={() => setForm({ ...form, isDelivery: false })}
                  />
                  <span>Самовывоз</span>
                </label>
                {!form.isDelivery && <div className="row-content">
                  <div className="row-addresses">
                    <label>
                      <span>Выберите пункт выдачи заказа:</span>
                    </label>
                  </div>
                  {form.areas.map((area: any) => (
                    <div className="row-addresses" key={ area.id }>
                      <label className="radio">
                        <input 
                          id={ `area-${area.id}` }
                          type="radio"
                          checked={area.selected}
                          onChange={(e) => setForm({...form, areas: form.areas.map((area: any) => ({
                            ...area,
                            selected: e.currentTarget.id.split('-')[1] == area.id
                          })) as any})}
                        />
                        <span>{ area.address }</span>
                        <div>Время работы: { area.workTime }</div>
                      </label>
                  </div>
                  ))}
                </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" />
      </div>
      <div className="order-block">
        <div className="order-button">
          <button className="btn btn-placeholder" onClick={submit}>
            Перейти к оплате
            <span className="price">{ cartSum + deliveryCost } ₽</span>  
          </button>
        </div> 
      </div>
    </div>
  )
}

export default Order;