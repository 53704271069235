import { useState } from 'react';
import IModal from '../../../interfaces/Modal';
import './styles.css';

const Modal = ({ modal, setModal }: {
  modal: IModal
  setModal: React.Dispatch<React.SetStateAction<any>>
}) => {
  const [quantity, setQuantity] = useState(1);

  const closeWithSave = () => {
    setModal({
      modal: false,
      item: {
        ...modal.item,
        quantity
      },
    });
  }

  const close = () => {
    setModal({
      modal: false,
      item: modal.item
    });
  }

  return (
    <div className="modal-wrap">
      <div className="modal">
        <div className="title">
          <h3>Добавить в корзину</h3>
          <img src="/img/close.svg" onClick={close}/>
        </div>
        <div className="content">
          <p className="name">{ modal.item.name }</p>
          <p className="description">{ modal.item.description }</p>
          { modal.item.measure && <span className="measure">{ modal.item.measure } г</span> }
        </div>
        <div className="controls">
          <button className="btn btn-black cart-add-button" onClick={closeWithSave}>Добавить</button>
          <div className="counter">
            <div className="minus" onClick={() => quantity > 1 && setQuantity(quantity - 1)}>
              <img src="img/minus.svg" />
            </div>
            <div className="count" data-count="1">{ quantity }</div>
            <div className="plus" onClick={() => setQuantity(quantity + 1)}>
              <img src="img/plus.svg" />
            </div>
          </div>
          <div className="amount">
            <span>Сумма</span>
            <div className="total">
              <span className="price">{ modal.item.price * quantity } </span>₽
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal;