import { useNavigate } from 'react-router-dom';
import './styles.css';
import { useContext } from 'react';
import { CartStateContext } from '../../utils/contexts';

const PaymentFail = () => {
  const navigate = useNavigate();
  const { setCartActive } = useContext(CartStateContext)!;

  return (
    <div className="payment-info">
      <div className="container">
        <div className="wrap">
          <img src="/img/fail.svg" />
          <h3>Оплата не прошла</h3>
          <p>Платеж был отменен или произошла ошибка. За подробностями вы можете обратиться в службу поддержки.</p>
          <button onClick={() => {
            setCartActive(false);
            navigate('/');
          }} className="btn">На главную</button>
        </div>
      </div>
    </div>
  )
}

export default PaymentFail;