import './styles.css';

const PageNotFound = () => {
  return (
    <div className="container not-found">
      <img src="/img/hotpot.svg"/>
      <p>К сожалению мы не успели приготовить эту страницу.<br />Попробуйте выбрать другую.</p>
      <a href="/" className="btn">На главную</a>
    </div>
  )
}

export default PageNotFound;