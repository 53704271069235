import { MouseEventHandler } from 'react';
import './styles.css';

const Category = ({ title, icon, selectedCategory, onClick }: {
  title: string,
  icon: string,
  selectedCategory: string,
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}) => {
  return (
    <a id={title} onClick={onClick}>
      <li>
        <span className="span" style={{ backgroundColor: selectedCategory == title ? '#f5e91f' : '#f8f8f8' }}>
          <img src={`img/${icon}.svg`} />
        </span>
        <p>{ title }</p>
      </li>
    </a>
  )
}

export default Category;