import './styles.css';

const Orderout = () => {
  return (
    <div className="order-out">
      <div className="order-out-content">
        <img src="/img/lock.svg" />
        <h3>Время заказа <span>10:00 – 23:00</span></h3>
        <p>Приносим извинения за доставленные неудобства!</p>
        <a href="/" className="btn">На главную</a>
      </div>
    </div>
  )
}

export default Orderout;