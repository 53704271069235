import { useNavigate } from 'react-router-dom';
import './styles.css';
import { CartStateContext } from '../../utils/contexts';
import { useContext } from 'react';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { setCartActive } = useContext(CartStateContext)!;

  return (
    <div className="payment-info">
      <div className="container">
        <div className="wrap">
          <img src="/img/success.svg" />
          <h3>Ваш заказ принят</h3>
          <p>В ближайшее время мы свяжемся с вами для уточнения деталей доставки.</p>
          <button onClick={() => {
            setCartActive(false);
            navigate('/');
          }} className="btn">На главную</button>
        </div>
      </div>
    </div>

  )
}

export default PaymentSuccess;