import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { CartStateContext, OrderStateContext } from '../../../utils/contexts';
import axios from 'axios';
import moment from 'moment';

const Cart = () => {
  const { order, setOrder } = useContext(OrderStateContext)!;
  const { cartActive, setCartActive } = useContext(CartStateContext)!;
  
  const [ deliveryCost, setDeliveryCost ] = useState(0);
  
  const navigate = useNavigate();
  
  let cartSum = 0;

  for (const position of order.items) {
    cartSum += position.quantity! * position.price;
  }

  const changePositionQuantity = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    const [positionId, action] = e.currentTarget.id.split('-');
    let updatedOrder = [];

    for (const position of order.items) {
      if (position.id.toString() == positionId) {
        let result = position.quantity! += action == 'plus' ? 1 : -1;

        if (result < 1) {
          continue;
        }

        updatedOrder.push({
          ...position,
          quantity: result
        });
      } else {
        updatedOrder.push(position);
      }
    }

    setOrder({ ...order, items: updatedOrder });
  }

  if (order.items.length == 0) {
    setCartActive(false);
  }

  useEffect(() => {
    async function getWorkTime() {
      const [startTime, endTime]= (await axios.post('/settings/byCodeGet', { code: 'work-time' }, { 
        headers: {
          Authentication: process.env.REACT_APP_KF_AUTH_KEY
        }
      })).data.data.value.split(', ');

      if (!(moment(startTime, [moment.ISO_8601, 'HH:mm']) < moment()
        && moment() < moment(endTime, [moment.ISO_8601, 'HH:mm']))) navigate('/orderout')
    } 
    getWorkTime();
  }, []);

  useEffect(() => {
    async function getDeliveryPrice() {
      const deliveryData = (await axios.post('/deliveryareas/byAreaDefaultGet', {}, {
        headers: {
          Authentication: process.env.REACT_APP_KF_AUTH_KEY
        }
      })).data;

      setDeliveryCost(deliveryData.data.priceFree > cartSum ? deliveryData.data.price : 0);
    }
    getDeliveryPrice();
  }, [order])

  return (
    <div className={`cart ${cartActive && order.items.length != 0 && 'cart-open'}`}>
      <div className="cart-content">
        <h3><img src="/img/close.svg" onClick={() => setCartActive(false)} /> Мой заказ</h3>
        <>
          <div className="cart-items">
            <ul>
              {order.items.map(item => (
                <li key={item.id}>
                  <div className="name">
                    { item.name }
                    { item.measure && <span>{ item.measure } г</span> }
                  </div>
                  <div className="counter">
                    <div className="minus">
                      <img id={`${item.id}-minus`} src="img/minus.svg" onClick={changePositionQuantity}/>
                    </div>
                    <div className="count" data-count="1">{ item.quantity }</div>
                    <div className="plus">
                      <img id={`${item.id}-plus`} src="img/plus.svg" onClick={changePositionQuantity} />
                    </div>
                  </div>
                  <div className="price">{ item.price } ₽</div>
                </li>
              ))}
            </ul>
          </div>
          <div className="cart-cultery">
            <div className="cultery">
              Количество приборов <div>Одноразовые приборы и салфетки</div>
            </div>
            <div>
              <select onChange={(e) => setOrder({ ...order, cutlery: Number(e.currentTarget.value)})}>
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
              </select>
            </div>
          </div>
          <div className="cart-sum">
            <div className="delivery-time">
              Время доставки
              <div>90&thinsp;–&thinsp;120 мин</div>
            </div>
            <div className="total-price">
              Итого
              <div>{cartSum + deliveryCost} ₽</div>
            </div>
          </div>
          <div className="cart-order-delivery-container">
            <div className="cart-order-delivery">
              <div className="freedelivery">Заказ будет доставлен { !deliveryCost ? 'бесплатно' : `за ${deliveryCost}₽` }</div>
            </div>
          </div>

        </>
      </div>
    </div>
  )
}

export default Cart;