import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Header from './components/layouts/Header';
import Home from './pages/Home';
import './styles.css';
import bridge, { UserInfo } from '@vkontakte/vk-bridge';
import { useEffect, useState } from 'react';
import { CartStateContext, OrderStateContext, UserContext } from './utils/contexts';
import Order from './pages/Order';
import PageNotFound from './pages/PageNotFound';
import IOrder from './interfaces/Order';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentFail from './pages/PaymentFail';
import Orderout from './pages/Orderout';

const App = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const vkUserId = queryParams.get('vk_user_id');

	const [user, setUser] = useState<UserInfo>();
  const [order, setOrder] = useState<IOrder>({ items: [], cutlery: 0 });
	const [cartActive, setCartActive] = useState<boolean>(false);

  useEffect(() => {
		if (!localStorage.getItem('order')) {
			localStorage.setItem('order', JSON.stringify({
				items: [],
				cutlery: 1
			}));
		}

		if (!localStorage.getItem('orderForm')) {
			localStorage.setItem('orderForm', JSON.stringify({
				phone: '',
				address: {
					latitude: '',
					longitude: '',
					title: ''
				},
				apartment: '',
				entrance: '',
				floor: '',
				intercom: '',
				comment: '',
				promocode: '',
				isCardPayment: true,
				isDelivery: true,
				areas: []
			}))
		}

		setOrder(JSON.parse(localStorage.getItem('order')!));
		
		async function getUser() {
			const data = await bridge.send('VKWebAppGetUserInfo', {
				user_id: Number(vkUserId)
			});
			setUser(data);
		}
		getUser();
  }, []);

  useEffect(() => {
		if (!order) return;
    localStorage.setItem('order', JSON.stringify(order));
  }, [order]);

	useEffect(() => {
		if (cartActive) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'scroll';
		}
	}, [cartActive])

	return (	
		<>
			{ ( !process.env.REACT_APP_DEBUG ? user : true) && <UserContext.Provider value={user!}>
				<OrderStateContext.Provider value={{ order, setOrder }}>
					<CartStateContext.Provider value={{ cartActive, setCartActive }}>
						<BrowserRouter>
							<Header />
							<Routes>
								<Route path="/" Component={Home}/>
								<Route path="/order" Component={Order} />
								<Route path="/orderout" Component={Orderout} />
								<Route path="/payment/success" Component={PaymentSuccess} />
								<Route path="/payment/fail" Component={PaymentFail} />
								<Route path="*" Component={PageNotFound} />
							</Routes>
						</BrowserRouter>
					</CartStateContext.Provider>
				</OrderStateContext.Provider>
			</UserContext.Provider> }
		</>
	)
}

export default App;
